
import { required, email } from "vuelidate/lib/validators";
import i18n from "../../../i18n";

export default {
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      languages: [
        {
          flag: require("@/assets/images/flags/russia.jpg"),
          language: "ru",
          title: "Русский",
        },
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English",
        },
      ],
      current_language: "ru",
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    currentFlagPath: function(){
      let result = '';
      //i18n.locale
      for(let key in this.languages){
        if(i18n.locale == this.languages[key].language){
          result = this.languages[key].flag;
        }
      }
      return result;
    }
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  validations: {
    email: { required, email },
    password: { required }
  },
  methods: {
    setLanguage(locale) {
      let reloadPage = false;
      if(i18n.locale != locale){
        reloadPage = true;
      }
      i18n.locale = locale;
      this.current_language = i18n.locale;
      localStorage.setItem("locale", locale);
      if(reloadPage){
        location.reload();
      }
    },
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        let email = this.email;
        let password = this.password;

        this.$store
            .dispatch("login", { email, password })
            .then(() => {
              // this.$store.dispatch("stopPreloader");
              this.$router.push("/");
            })
            .catch(err => {
              this.$store.dispatch('notificationError', err.message);
            });
      }
    }
  },
  mounted(){
    let locale = localStorage.getItem("locale");
    if(locale){
      this.setLanguage(locale);
    }
  }
};
